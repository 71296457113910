import { ApiService } from "../../../services/apiService";

import {
  POST_UME_URL,
  UPLOAD_BASE_URL,
  routes,
  apiKey,
} from "../../../services/paths";
import { history } from "../../../shared/_helpers/history";
import * as types from "../../action-constants/onboarding";
import { handleRequestErrors } from "../../../shared/utils";
import { toast } from "react-toastify";

export const candidateLogin = (payload) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload,
  };
};

export const candidateLoginFailure = (payload) => {
  return {
    type: types.LOGIN_USER_FAILURE,
    payload,
  };
};
const candidateLoginPending = (payload) => {
  return {
    type: types.LOGIN_USER_PENDING,
    payload,
  };
};
export const getCandidateResults = (payload) => {
  return {
    type: types.CANDIDATE_RESULTS,
    payload,
  };
};

const loadCandidateResults = (payload) => {
  return {
    type: types.LOADING_RESULTS,
    payload,
  };
};

export const userLogout = () => ({
  type: types.LOGOUT,
});

const invoiceGeneration = (payload) => {
  return {
    type: types.PAYMENT_INVOICE,
    payload,
  };
};

const loadingInvoice = (payload) => {
  return {
    type: types.LOADING_INVOICE,
    payload,
  };
};

export const postUmeLogin =
  (data, shouldRedirect = true) =>
  async (dispatch) => {
    dispatch(candidateLoginFailure(""));
    dispatch(candidateLoginPending(true));
    let apiConsume = ApiService.request({
      url: `${routes.CANDIDATE_LOGIN}`,
      http_method: "POST",
      data,
      headers: { "X-Signature": apiKey },
    });
    apiConsume
      .then(async ({ data }) => {
        dispatch(candidateLoginPending(false));
        await dispatch(candidateLogin(data));
        if (shouldRedirect) {
          history.replace("/info");
        }
      })
      .catch((err) => {
        if (err && err?.response?.data?.status === 404) {
          dispatch(candidateLoginFailure("Invalid login details"));
        } else if (err?.message && err?.message.includes("Network Error")) {
          dispatch(
            candidateLoginFailure("Please check your network and try again.")
          );
        } else {
          dispatch(
            candidateLoginFailure("An error occured, please try again.")
          );
        }
        dispatch(candidateLoginPending(false));
        return err;
      });
  };

export const addOrUpdateResult = async (data, category = "OLEVEL") => {
  return await ApiService.request({
    url:
      category === "ALEVEL"
        ? `${routes.ADD_OR_UPDATE_ALEVEL_RESULT}`
        : `${routes.ADD_OR_UPDATE_RESULT}`,
    http_method: "POST",
    data,
    headers: { "X-Signature": apiKey },
  })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      if (err?.message && err?.message.includes("Network Error")) {
        toast.error("Please check your network and try again.");
      } else if (err?.response?.status < 500) {
        toast.error(err?.response?.data);
      } else {
        toast.error("An error occured, please try again.");
      }
      handleRequestErrors(err);
      return err;
    });
};

export const uploadDocuments = async (data, requestType) => {
  return await ApiService.request({
    url: `${UPLOAD_BASE_URL}/${requestType}`,
    http_method: "POST",
    data,
    headers: { "X-Signature": apiKey },
  });
};
export const checkCandidateEligibility = (data) => async (dispatch) => {
  dispatch(candidateLoginFailure(""));
  dispatch(candidateLoginPending(true));

  try {
    const response = await ApiService.request({
      url: `${routes.CHECK_ELIGIBILITY}`,
      http_method: "POST",
      data,
      headers: { "X-Signature": apiKey },
    });

    dispatch(candidateLoginPending(false));
    return response;
  } catch (err) {
    if (err && err?.response?.status === 404) {
      dispatch(candidateLoginFailure(err?.response?.data));
    } else if (
      err &&
      err?.response &&
      err?.response?.data?.title ===
        "One or more validation errors occurred." &&
      err?.response?.data?.errors &&
      Object.values(err?.response?.data?.errors).length >= 1
    ) {
      dispatch(
        candidateLoginFailure(
          Object.values(err?.response?.data?.errors).join(";")
        )
      );
    } else if (err?.message && err?.message.includes("Network Error")) {
      dispatch(
        candidateLoginFailure("Please check your network and try again.")
      );
    } else {
      dispatch(candidateLoginFailure("An error occurred, please try again."));
    }
    dispatch(candidateLoginPending(false));
    return err;
  }
};

export const getResults =
  (registrationNumber, category = "OLEVEL") =>
  async (dispatch) => {
    dispatch(loadCandidateResults(true));
    let request = ApiService.request({
      url:
        category === "ALEVEL"
          ? `${POST_UME_URL}/ALevelResult`
          : `${POST_UME_URL}/OLevelResults`,
      http_method: "POST",
      data: { registrationNumber },
      headers: { "X-Signature": apiKey },
    });
    request
      .then((data) => {
        dispatch(loadCandidateResults(false));
        if (data.status === 200) {
          if (category === "OLEVEL") {
            const { oLevelResult1, oLevelResult2 } = data.data;
            dispatch(getCandidateResults({ oLevelResult1, oLevelResult2 }));
          } else {
            dispatch(getCandidateResults(data?.data));
          }
        }
      })
      .catch((err) => {
        dispatch(loadCandidateResults(false));
        handleRequestErrors(err);
        return err;
      });
  };

export const deleteResult = async (registrationNumber, oLevelResultId) => {
  let request = ApiService.request({
    url: `${POST_UME_URL}/DeleteOLevelResult?oLevelResultId=${oLevelResultId}&registrationNumber=${registrationNumber}`,
    http_method: "DELETE",
    headers: { "X-Signature": apiKey },
  });
  request
    .then((data) => {
      return data;
    })
    .catch((err) => {
      handleRequestErrors(err);
      return err;
    });
};

export const generatePostUmeFee = (payload) => async (dispatch) => {
  dispatch(loadingInvoice(true));
  let request = ApiService.request({
    url: routes.POST_UTME_INVOICE,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": apiKey },
  });
  request
    .then((data) => {
      dispatch(loadingInvoice(false));
      if (data.status === 200) {
        dispatch(invoiceGeneration(data?.data));
      }
    })
    .catch((err) => {
      dispatch(loadingInvoice(false));
      handleRequestErrors(err);
      return err;
    });
};

export const printAcknowledgmentSlip = (payload) => {
  return ApiService.request({
    url: routes.PRINT_ACKNOWLEDGMENT_SLIP,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": apiKey },
  });
};

export const updatePhoneNumber = (data) => async (dispatch) => {
  dispatch(candidateLoginFailure(""));
  dispatch(candidateLoginPending(true));

  try {
    const response = await ApiService.request({
      url: `${routes.UPDATE_PHONE_NUMBER}`,
      http_method: "PUT",
      data,
      headers: { "X-Signature": apiKey },
    });

    dispatch(candidateLoginPending(false));
    return response;
  } catch (err) {
    if (
      err &&
      (err?.response?.status === 404 || err?.response?.status === 400)
    ) {
      dispatch(candidateLoginFailure(err?.response?.data));
    } else if (err?.message && err?.message.includes("Network Error")) {
      dispatch(
        candidateLoginFailure("Please check your network and try again.")
      );
    } else {
      dispatch(candidateLoginFailure("An error occurred, please try again."));
    }
    dispatch(candidateLoginPending(false));
    return err;
  }
};

export const Logout = () => (dispatch) => {
  // localStorage.removeItem("adXAuth");
  // localStorage.removeItem("state");
  dispatch(userLogout());
  history.push("/login");
};
