import React from "react";

const QuickLinks = () => {
  const loginLink =
    window.location.hostname === "localhost"
      ? "http://localhost:3000/login"
      : window.location.hostname === "staging-postutme.ibbuuniversity.com.ng"
      ? "https://staging-postutme.ibbuuniversity.com.ng/login"
      : "https://postutme.ibbu.edu.ng/login";

  // const admissionCheckLink =
  //   window.location.hostname === "localhost" ||
  //   window.location.hostname ===
  //     "eduportal-staging-ibbu-postutme-webapp.azurewebsites.net"
  //     ? "https://eduportal-staging-ibbu-webapp.azurewebsites.net/admissions/check-status"
  //     : "https://ibbuportals.ibbu.edu.ng/admissions/check-status";

  return (
    <div className="quick-links">
      <h6>UTME/DE QUICK LINKS</h6>
      <p>
        UTME/DE:{" "}
        <a href={loginLink} target="_blank" rel="noreferrer">
          Click here
        </a>{" "}
        to Log into your UTME results submission portal account.
      </p>
    </div>
  );
};

export default QuickLinks;
